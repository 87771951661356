FilePond.registerPlugin(
    // FilePondPluginImagePreview,
    FilePondPluginImageExifOrientation,
    FilePondPluginFileValidateType,
    FilePondPluginFileValidateSize,
);


FilePond.create(document.querySelector('input[id="filePondGallery"]'));
FilePond.setOptions({
    allowDrop: true,
    allowMultiple: true,
    allowReplace: true,
    allowRevert: false,
    allowRemove: false,
    allowReorder: false,
    maxParallelUploads: 2,
    instantUpload: true,
    chunkUpload: true,
    required: true,
    maxFiles: 50,
    labelIdle: 'Перетяните сюда файлы или <span class="filepond--label-action"> выберите </span>',
    labelFileLoading: 'загружаю',
    labelFileProcessing: 'отправка на сервер',
    labelFileLoadError: 'ошибка загрузки',
    labelButtonProcessItem: 'отправить',
    labelFileProcessingComplete: 'успешно',
    labelTapToCancel: 'нажмите для отмены',
    acceptedFileTypes: ['image/png', 'image/jpeg'],
    allowImageExifOrientation: true,
    allowFileTypeValidation: true,
    allowFileSizeValidation: true,
    maxFileSize: '10MB',
    labelMaxFileSizeExceeded: 'превышен допустимый размер',
    labelMaxFileSize: 'Максимально допустимый размер — {filesize}',
    server: {
        // url: 'http://192.168.33.10',
        process: {
            url: '/filepond/gallery',
            method: 'POST',
            timeout: 7000,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
        },
        fetch: null,
        revert: null,
        restore: null,
    }
});
