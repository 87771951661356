window._ = require('lodash');

try {
    // window.Popper = require('popper.js').default;
    // window.$ = window.jQuery = require('jquery');
    // require('bootstrap');

    window.$ = window.jQuery = require('jquery');
    window = require('jquery-ui');
    require('bootstrap');
    require('select2');
    window.Swal = require('sweetalert2');
    require('jquery-smooth-scroll');
    require('jquery-toast-plugin')
    require('../../public/backenderJS/assets/plugins/datepicker/datepicker.min')

    window.FilePond = require('filepond');
    window.FilePondPluginImagePreview = require('filepond-plugin-image-preview');
    window.FilePondPluginImageExifOrientation = require('filepond-plugin-image-exif-orientation');
    window.FilePondPluginFileValidateType = require('filepond-plugin-file-validate-type');
    window.FilePondPluginFileValidateSize = require('filepond-plugin-file-validate-size');

    require('owl.carousel');

    // window.PhotoSwipe = require('photoswipe');
    // window.PhotoSwipeUI_Default = require('./../../node_modules/photoswipe/src/js/ui/photoswipe-ui-default.js');

} catch (e) {
    console.log(e)
}

$(document).ready(function () {

    $('.autoclose-date').datepicker({
        language: 'ru',
        autoClose: true,
        // minDate: new Date(),
        dateFormat: 'dd.mm.yyyy',
        orientation: 'top left',
    });

    $("#compactView").click(function () {
        $('#galleryBlock').addClass('compact');
        $('#compactViewOff').toggle();
        $('#compactView').toggle();
    });

    $("#compactViewOff").click(function () {
        $('#galleryBlock').removeClass('compact');
        $('#compactViewOff').toggle();
        $('#compactView').toggle();
    });


// import Echo from 'laravel-echo';
// window.Pusher = require('pusher-js');
//
// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     encrypted: true
// });


///////////////////////////////////////////////////////////////////////////
// Loader
        setTimeout(() => {
            $("#loader").fadeToggle(250);
        }, 700); // hide delay when page load
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// Go Back
    $(".goBack").click(function () {
        window.history.back();
    });
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// Tooltip
    $(function () {
        $('[data-toggle="tooltip"]').tooltip()
    })
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// Input
    $(".clear-input").click(function () {
        $(this).parent(".input-wrapper").find(".form-control").focus();
        $(this).parent(".input-wrapper").find(".form-control").val("");
        $(this).parent(".input-wrapper").removeClass("not-empty");
    });
// active
    $(".form-group .form-control").focus(function () {
        $(this).parent(".input-wrapper").addClass("active");
    }).blur(function () {
        $(this).parent(".input-wrapper").removeClass("active");
    })
// empty check
    $(".form-group .form-control").keyup(function () {
        var inputCheck = $(this).val().length;
        if (inputCheck > 0) {
            $(this).parent(".input-wrapper").addClass("not-empty");
        } else {
            $(this).parent(".input-wrapper").removeClass("not-empty");
        }
    });
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// Searchbox Toggle
    $(".toggle-searchbox").click(function () {
        $("#search").fadeToggle(200);
        $("#search .form-control").focus();
    });
///////////////////////////////////////////////////////////////////////////

///////////////////////////////////////////////////////////////////////////
// Owl Carousel
    $('.carousel-full').owlCarousel({
        loop: true,
        margin: 8,
        nav: false,
        items: 1,
        dots: false,
    });
    $('.carousel-single').owlCarousel({
        stagePadding: 30,
        loop: true,
        margin: 16,
        nav: false,
        items: 1,
        dots: false,
    });
    $('.carousel-multiple').owlCarousel({
        stagePadding: 32,
        loop: true,
        margin: 16,
        nav: false,
        items: 2,
        dots: false,
    });
    $('.carousel-small').owlCarousel({
        stagePadding: 32,
        loop: true,
        margin: 16,
        nav: false,
        items: 5,
        dots: false,
    });
    $('.carousel-slider').owlCarousel({
        loop: true,
        margin: 8,
        nav: false,
        items: 1,
        dots: true,
    });

});


window.onload = function() {

    var ibVersion,
        addimageboxVersion = document.querySelectorAll('script[data-addimagebox-version]')[0];

    if (addimageboxVersion) {
        ibVersion = addimageboxVersion.getAttribute('data-addimagebox-version');
    } else {
        ibVersion = '1.1.0';
    }

    var filePath = 'https://tobiasroeder.github.io/imagebox/' + ibVersion + '/imagebox.min.';

    // create link tag for the css file
    var cssLink = document.createElement('link');
    cssLink.setAttribute('rel', 'stylesheet')
    cssLink.setAttribute('type', 'text/css');
    cssLink.setAttribute('href', filePath + 'css');
    document.head.appendChild(cssLink);

    // create link tag for the css file
    var jsScript = document.createElement('script');
    jsScript.setAttribute('src', filePath + 'js');
    document.body.appendChild(jsScript);
}